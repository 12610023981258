window.portal = {
	ulpConsumerTerminate: () => {
		import(/* webpackChunkName: "ulpConsumerTerminate" */ './termination/terminate');
	},
	ulpSoftEndpointDeactivate: () => {
		import(/* webpackChunkName: "ulpSoftEndpointDeactivate" */ './ulp_endpoint/deactivate');
	},
	ulpSoftEndpointResetMFA: () => {
		import(/* webpackChunkName: "ulpSoftEndpointResetMFA" */ './ulp_endpoint/reset_mfa');
	},
	ulpUserInvitationResend: () => {
		import(/* webpackChunkName: "ulpUserInvitationResend" */ './ulp_user_invitation/resend');
	},
	ulpUserInvitationResendAll: (root: HTMLElement, url: string) => {
		import(/* webpackChunkName: "ulpUserInvitationResendAll" */ './ulp_user_invitation/resend-all').then(
			(module) => {
				module.mountResendAll(root, url);
			}
		);
	},
	ulpUserInvitationSignup: () => {
		import(/* webpackChunkName: "ulpUserInvitationSignup" */ './ulp_user_invitation/signup');
	},
	ulpGoogleInteropUploader: () => {
		import(/* webpackChunkName: "ulpGoogleInteropUploader" */ './ulp_google_interop/index-uploader');
	},
	ulpGoogleInteropResend: () => {
		import(/* webpackChunkName: "ulpGoogleInteropResend" */ './ulp_google_interop/resend');
	},
	ulpTeamsInteropResend: () => {
		import(/* webpackChunkName: "ulpTeamsInteropResend" */ './ulp_teams_interop/resend');
	},
	ulpTeamsRoomInteropResend: () => {
		import(/* webpackChunkName: "ulpTeamsRoomInteropResend" */ './ulp_teams_room_interop/resend');
	},
	ulpDomainHostingResend: () => {
		import(/* webpackChunkName: "ulpDomainHostingResend" */ './ulp_domain_hosting/resend');
	},
	ulpDomainHostingVerify: () => {
		import(/* webpackChunkName: "ulpDomainHostingVerify" */ './ulp_domain_hosting/verify');
	},
	ulpMSTenantDomainResend: () => {
		import(/* webpackChunkName: "ulpMSTenantDomainResend" */ './ulp_ms_tenant_domain/resend');
	},
	ulpMSTenantDomainVerify: () => {
		import(/* webpackChunkName: "ulpMSTenantDomainVerify" */ './ulp_ms_tenant_domain/verify');
	},
	ulpInfinityLicenseResend: () => {
		import(/* webpackChunkName: "ulpInfinityLicenseResend" */ './ulp_infinity_license/resend');
	},
	teams_trust_setup_pexip: () => {
		import(/* webpackChunkName: "teams_trust_setup_pexip" */ './ulp_teams_interop/teams_trust_setup_pexip');
	},
	toolchain_test: () => {
		import(/* webpackChunkName: "batchOperations" */ './toolchain_test/index');
	},
	main: (root: HTMLElement, brand: any, user: any, showSearcher: boolean) => {
		import(/* webpackChunkName: "mainNavbar" */ 'src/portal/apps/main/index').then((module) => {
			module.mountMain(root, brand, user, showSearcher);
		});
	},
	inviteToAnalyticsAccess: (root: HTMLElement, url: string, state: any) => {
		import(/* webpackChunkName: "inviteToAnalyticsAccess" */ './analytics_access/invite_to_analytics_access').then(
			(module) => {
				module.mountInviteToAnalyticsAccess(root, url, state);
			}
		);
	},
	profileResetAnalytics2FA: (root: HTMLElement, url: string) => {
		import(/* webpackChunkName: "profileResetAnalytics2FA" */ './analytics_access/reset_2fa').then((module) => {
			module.mountResetAnalytics2FA(root, url);
		});
	},
	revokeAnalyticsAccess: (root: HTMLElement, url: string) => {
		import(/* webpackChunkName: "revokeAnalyticsAccess" */ './analytics_access/revoke_analytics_access').then(
			(module) => {
				module.mountRevokeAnalyticsAccess(root, url);
			}
		);
	},
	dnsCheckerInline: (url: string) => {
		import(/* webpackChunkName: "dnsCheckerInline" */ './dns_checker/inline').then((module) => {
			module.applyDnsCheckerInline(url);
		});
	},
	dnsCheckerStandalone: () => {
		import(/* webpackChunkName: "dnsCheckerStandalone" */ './dns_checker/index');
	},
	licenseDetails: () => {
		import(/* webpackChunkName: "licenseDetails" */ './ulp_infinity_license/index-details');
	},
	statsDisplay: () => {
		import(/* webpackChunkName: "statsDisplay" */ './ulp_infinity_license/stats-display').then((module) => {
			module.applyStatsDisplay();
		});
	},
};
